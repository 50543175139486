import Button from "@/components/button";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Input from "@/components/input";
import axios from "@/axios/osint.js";
import {DateTime} from "luxon";
import nLoader from "@/components/n-loader";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import toolsHelper from "@/mixins/toolsHelper";
const ImagePlaceholder = () => import("@/components/image-placeholder");

export default {
    name: "tiktok",
    /**
     * @mixin
     * @name toolsHelper - mixin to control tools & monitoring actions
     */
    mixins: [toolsHelper],
    components: {
        "neo-button": Button,
        "neo-input": Input,
        VueMultiselect,
        nLoader,
        NeoAnalyse,
        "download-csv": JsonCSV,
        ImagePlaceholder,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            inputOptions: [
                {
                    group: "Field Type",
                    categories: ["Username", "Hashtag"],
                },
            ],
            inputValue: "Username",
            selectedItemUsername: null,
            searchUsername: "",
            searchHashtag: "",
            searchType: "username",
            triggerRoute: true,
            searchKeyword: "",
            usernameList: [],
            showResult: false,
            loadingFeed: false,
            nextpageHashtag: null,
            nextpageUserfeed: null,
            loaderController: {
                tiktok: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                search: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            userData: {},
            userFeed: [],
            lazyLoading: false,
            csvData: {
                userDetails: [],
                userFeed: [],
                hashtagFeed: [],
            },
            hashtagData: {},
            hashtagFeed: [],
            selectVal: "",
            searchClicked: false,
            tabName: "",
            request_id: "",
        };
    },
    watch: {
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // searchKeyword() {
        //     this.showResult = false;
        // },

        // selectedItemUsername: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseUsernames"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return {
                userFeed: ["author", "create_time", "music", "share_info", "statistics", "video"],
                hashtagFeed: ["author", "create_time", "music", "share_info", "statistics", "video"],
            };
        },

        query() {
            return this.selectedItemUsername?.value || this.searchKeyword;
        },

        /**
         * @property
         * @name isMonitoringTab
         * @return {Boolean} - check if monitoring tab is selected
         */

        isMonitoringTab() {
            return this.tabName === "monitoring";
        },
    },
    mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "tiktok-tool");
        if (dataRes) this.redirectToQuery(dataRes);
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "tiktok-tool");
        // this.SET_ALL_TOOLS_DATA({ ...this._data, "tool-name": "tiktok-tool" });
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseUsernames"]),
        async onTabScroll() {
            if (document.getElementById("list").scrollTop < 479) {
                this.scrollTop = document.getElementById("list").scrollTop;
            }
            if ((this.nextpageHashtag != null && this.inputValue === "Hashtag") || (this.nextpageUserfeed != null && this.inputValue === "Username")) {
                if (document.getElementById("list").scrollTop > document.getElementById("list").scrollHeight - document.getElementById("list").offsetHeight - 50) {
                    if (!this.lazyLoading) {
                        this.lazyLoading = true;
                        if (this.inputValue === "Username") await this.getTiktokUserFeedData(this.nextpageUserfeed);
                        else await this.getTikTokFeedData(this.nextpageHashtag);
                    }
                }
            }
        },
        async getToolData() {
            this.usernameList = await this.getDropdownData("username");
        },
        intToString(num, fixed = 2) {
            if (!num) {
                return null;
            } // terminate early
            if (num === 0) {
                return "0";
            } // terminate early
            fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
            var b = num.toPrecision(2).split("e"), // get power
                k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
                c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
                d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
                e = d + ["", "K", "M", "B", "T"][k]; // append power
            return e;
        },
        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },
        setInput() {
            if (this.inputValue === "Username") {
                this.searchType = "username";
                this.searchUsername = this.selectedItemUsername.value;
            } else {
                this.searchType = "hastag";
                this.searchUsername = this.selectedItemUsername.value;
            }
        },
        setTheCustomInput(value) {
            if (this.inputValue === "Username") {
                this.searchType = "username";
                this.searchUsername = value;
                this.selectedItemUsername = {
                    value: value,
                };
            } else {
            }
        },
        manualSearch() {
            this.triggerRoute = false;
            this.search();
        },
        setTypeInput(value) {
            this.showResult = false;
            this.searchClicked = true;
            this.searchKeyword = "";
            this.selectedItemUsername = null;
            this.selectVal = "";
        },
        async search() {
            console.log("search query");
            this.showResult = false;

            if (this.inputValue == "Username") {
                this.searchClicked = true;
                this.$emit("searched", {data: {username: this.searchUsername}, source: "tiktok"});
                await this.getTiktokUsernameData();
                await this.setSearchedUser(this.searchUsername, "username");
                this.triggerRoute = true;
            } else if (this.inputValue == "Hashtag") {
                this.searchClicked = true;
                await this.getTikTokHashtagData();
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "tiktok",
                val_type: this.selectedItem?.val_type || "username",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`usernameList`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`usernameList`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        getOptionsList(list) {
            return [
                {
                    group: "Username",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "tiktok", [...this.usernameList], data);
            allChecked = [...this.usernameList]?.length ? ([...this.usernameList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },
        formatTime(ts, format = "EEE, dd LLLL yyyy, hh:mm a") {
            return DateTime.fromSeconds(ts).toFormat(format);
        },
        formatDuration(seconds) {
            return Math.floor(seconds / 1000);
        },
        async getTikTokHashtagData() {
            console.log(this.searchKeyword);
            this.clearData();
            this.startLoader("tiktok");
            this.loadingFeed = true;
            let url = "/tiktok/hashtag/stats";
            let data = {
                hashtag: this.searchKeyword,
            };

            axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    if (response.data.error) {
                        this.$toast.error(response.data.error);
                        this.resetLoader("tiktok");
                        this.showResult = false;
                    } else {
                        this.showResult = true;
                        this.hashtagData = response.data;
                        this.getTikTokFeedData();
                        if (document.getElementById("list")) {
                            document.getElementById("list").addEventListener("scroll", this.onTabScroll);
                            document.getElementById("list").scrollTop += 1;
                            document.getElementById("list").scrollTop -= 1;
                        }
                        this.request_id = response?.data?.query_id;
                    }
                })
                .catch((error) => {
                    this.resetLoader("tiktok");
                    let message = "Please enter a valid input";
                    if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                    this.$toast.error(message);
                });
            this.redirectTo();
        },
        async getTikTokFeedData(isNext = null) {
            let data = {
                hashtag: this.searchKeyword,
            };
            if (isNext != null) {
                data.cursor = isNext;
                this.startLoader("tiktok");
            }
            const feed = axios.post("/tiktok/hashtag/feed", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            try {
                if (feed.data && feed.data.pagination) {
                    this.nextpageHashtag = feed.data.pagination.next_page;
                }
                if (feed && feed.data && feed.data.posts?.length >= 0) {
                    this.loadingFeed = false;
                    this.lazyLoading = false;
                    let userfeedDetails = feed.data.posts.map((el) => ({
                        ...el,
                        showMoreDesc: false,
                    }));
                    this.hashtagFeed = this.hashtagFeed.concat(userfeedDetails);
                    this.csvData.hashtagFeed = this.convertToCSV(this.hashtagFeed);
                } else if (feed && feed.data && feed.data.posts?.length === 0 && !this.nextpageHashtag) {
                    this.$toast.error("No data found");
                    this.loadingFeed = false;
                    this.lazyLoading = false;
                    this.nextpageHashtag = null;
                } else if (feed && feed.data.message) {
                    this.loadingFeed = false;
                    this.lazyLoading = false;
                    this.$toast.error(feed.data.message);
                    this.nextpageHashtag = null;
                } else if (feed) {
                    this.loadingFeed = false;
                    this.$toast.error("Something went wrong. Please try again after some time");
                }
            } catch (feedError) {
                this.loadingFeed = false;
                this.lazyLoading = false;
                this.nextpageHashtag = null;
                if (this.hashtagFeed.length === 0) {
                    this.hashtagFeed = [];
                    this.csvData.hashtagFeed = [];
                }
                this.csvData.userFeed = [];

                if (feedError && feedError.message) {
                    this.$toast.error(feedError.message);
                } else if (feedError.response && feedError.response.status !== 406) {
                    this.$toast.error("Something went wrong. Please try again after some time");
                }
            }

            this.resetLoader("tiktok");
        },
        clearData() {
            this.userData = {};
            this.userFeed = [];
            this.csvData.userFeed = [];
            this.hashtagFeed = [];
            this.hashtagData = {};
            this.csvData.hashtagFeed = [];
            this.loadingFeed = false;
            this.lazyLoading = false;
            this.nextpageUserfeed = null;
            this.nextpageHashtag = null;
            this.showResult = false;
            this.resetLoader("tiktok");
        },
        async getTiktokUsernameData() {
            // allGithubUsernameData
            this.clearData();
            this.startLoader("tiktok");
            let data = {};
            let url = "/tiktok/user";
            data = {
                username: this.searchUsername,
            };
            // this.$emit("searched", {
            //     data: { username: this.searchUsername },
            //     source: "tiktok",
            // });
            this.loadingFeed = true;
            // this.lazyLoading = false;
            const response = axios.post(url, data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            try {
                if (response.data.error) {
                    this.$toast.error(response.data.error);
                    this.showResult = false;
                    this.loadingFeed = false;
                } else if (response && response.data && response.data.data) {
                    let temp = [];
                    this.showResult = true;
                    this.userData = response.data.data;
                    temp.push(this.userData);
                    this.csvData.userDetails = this.convertToCSV(temp);
                    this.$emit("searchResult", {data: response.data, source: "tiktok", searchType: "username"});
                    // this.loadingFeed = false;
                    this.getTiktokUserFeedData();
                    if (document.getElementById("list")) {
                        document.getElementById("list").addEventListener("scroll", this.onTabScroll);
                        document.getElementById("list").scrollTop += 1;
                        document.getElementById("list").scrollTop -= 1;
                    }
                } else if (response.data && response.data.message) {
                    let message = response.data.message ? response.data.message : "Something went wrong ! Please try again later.";
                    this.$toast.error(message);
                    this.loadingFeed = false;
                    this.resetLoader("tiktok");
                }
                this.request_id = response?.data?.query_id;
            } catch (error) {
                this.resetLoader("tiktok");
                let message = "Something went wrong ! Please try again later.";
                if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                if (error.response && error.response.status !== 406) this.$toast.error(message);
                this.loadingFeed = false;
            }
            this.redirectTo();
        },
        async getTiktokUserFeedData(isNext = null) {
            let data = {};
            data = {
                username: this.searchUsername,
            };

            if (isNext != null) {
                data.max_cursor = isNext;
                this.startLoader("tiktok");
            }
            axios
                .post("/tiktok/feed", data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((feed) => {
                    if (feed.data && feed.data.pagination) {
                        this.nextpageUserfeed = feed.data.pagination.next_page;
                    }
                    if (feed && feed.data && feed.data.posts?.length >= 0) {
                        this.loadingFeed = false;
                        let userfeedDetails = feed.data.posts.map((el) => ({
                            ...el,
                            showMoreDesc: false,
                        }));
                        this.userFeed = this.userFeed.concat(userfeedDetails);
                        this.lazyLoading = false;
                        this.csvData.userFeed = this.convertToCSV(this.userFeed);
                    } else if (feed && feed.data && feed.data.posts?.length == 0) {
                        this.$toast.error("No data found");
                        this.loadingFeed = false;
                        this.lazyLoading = false;
                        this.nextpageUserfeed = null;
                    } else if (feed && feed.data.message) {
                        this.loadingFeed = false;
                        this.lazyLoading = false;
                        this.nextpageUserfeed = null;
                        this.$toast.error(feed.data.message);
                    } else if (feed) {
                        this.loadingFeed = false;
                        this.lazyLoading = false;
                        this.nextpageUserfeed = null;
                        this.$toast.error("Something went wrong. Please try again after some time");
                    }
                })
                .catch((feedError) => {
                    this.loadingFeed = false;
                    this.lazyLoading = false;
                    this.nextpageUserfeed = null;
                    if (this.userFeed.length === 0) {
                        this.userFeed = [];
                        this.csvData.userFeed = [];
                    }
                    this.csvData.hashtagFeed = [];
                    if (feedError && feedError.message) {
                        this.$toast.error(feedError.message);
                    } else {
                        this.$toast.error("Something went wrong. Please try again after some time");
                    }
                });
            this.resetLoader("tiktok");
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        activateReadMore(id) {
            this.userFeed = this.userFeed.map((el) => {
                if (el.aweme_id === id) {
                    return {...el, showMoreDesc: !el.showMoreDesc};
                } else {
                    return {...el};
                }
            });
        },

        activateReadMoreFeed(id) {
            this.hashtagFeed = this.hashtagFeed.map((el) => {
                if (el.aweme_id === id) {
                    return {...el, showMoreDesc: !el.showMoreDesc};
                } else {
                    return {...el};
                }
            });
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const type = Object.keys(selected_query?.query)[0];
                const value = Object.values(selected_query?.query)[0];
                if (type === "username") {
                    this.searchKeyword = "";
                    this.searchUsername = value;
                    this.selectedItemUsername = {
                        value: value,
                    };
                    this.inputValue = "Username";
                } else if (type === "hashtag") {
                    this.searchUsername = "";
                    this.searchKeyword = value;
                    this.inputValue = "Hashtag";
                }
                this.selectVal = value;
                this.searchType = type;

                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close(type) {
            this.selectedItemUsername = {
                value: this.selectVal,
            };
            this.searchType = "username";
            this.searchUsername = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
        onInput() {
            this.showResult = false;
            this.searchClicked = false;
        },
    },
};
